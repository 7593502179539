<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 transition-opacity bg-opacity-75 bg-cblack" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ConsentModalContent
              v-model="consense"
              @accept-all="onClickAcceptAll"
              @decline-all="onClickDeclineAll"
              @save="saveSettings"
            />
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick } from 'vue'
import { Dialog, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { useCookie, useNuxtApp } from '#app'
import { useGtm } from '@gtm-support/vue-gtm'

const isOpen = ref(false)

onMounted(() => {
  const consentCookie = useCookie('cc')

  // NOTE: check if the user has an old consent cookie without the advertising key
  const consentCookieKeyCount = consentCookie.value
    ? Object.keys(consentCookie.value).length
    : 0

  isOpen.value = !consentCookie.value || consentCookieKeyCount < 3
})

const consense = ref({
  necessary: true,
  analytics: false,
  advertising: false,
})

function onClickAcceptAll() {
  consense.value.analytics = true
  consense.value.advertising = true
  saveSettings()
}

function onClickDeclineAll() {
  consense.value.analytics = false
  consense.value.advertising = false
  saveSettings()
}

async function saveSettings() {
  const expiryDate = new Date()
  expiryDate.setMonth(expiryDate.getMonth() + 6)
  const consentCookie = useCookie('cc', {
    expires: expiryDate,
    path: '/',
  })
  consentCookie.value = JSON.stringify(consense.value)
  isOpen.value = false

  // since useCookie create reactive reference we have to make sure to wait
  // for the mutation of the reactive state before calling enableTracking
  await nextTick()

  if (consense.value.analytics) {
    const { $enableTracking } = useNuxtApp()
    $enableTracking()
  }

  if (consense.value.advertising) {
    const gtm = useGtm()
    if (gtm) gtm.enable()
  }
}
</script>
