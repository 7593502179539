import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45global from "/vercel/path0/middleware/redirect.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/vercel/path0/middleware/auth.ts"),
  "order-request-form": () => import("/vercel/path0/middleware/orderRequestForm.ts")
}