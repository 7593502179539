<template>
  <div class="bg-gray-100">
    <NuxtLayout name="public">
      <ErrorMessage
        :error="error"
        :is-trace-log-enabled="isTraceLogEnabled"
        @click-home-button="onClickHandleError"
        @click-contact-button="onClickHandleError('/kontakt')"
      />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import * as Sentry from '@sentry/vue'

const props = defineProps({
  error: {
    type: Object,
    default: null,
  },
})

onMounted(() => {
  if (props.error) {
    Sentry.captureException(props.error)
  }
})

const config = useRuntimeConfig()
const isTraceLogEnabled = config.public.IS_TRACE_LOG_ENABLED

const onClickHandleError = (path = '/') => clearError({ redirect: path })
</script>
