<template>
  <div
    class="min-h-full px-4 py-16 sm:px-6 sm:py-32 md:grid md:place-items-center lg:px-8"
  >
    <div class="max-w-4xl mx-auto">
      <main class="sm:flex">
        <p class="text-4xl font-extrabold text-cred sm:text-5xl">
          {{ error.statusCode }}
        </p>
        <div class="sm:ml-6">
          <div class="sm:border-l sm:border-gray-200 sm:pl-6">
            <h1
              class="text-4xl font-extrabold tracking-tight text-cblack sm:text-5xl"
            >
              {{ errorMessage.message }}
            </h1>
            <p v-if="errorMessage.sub" class="mt-2 text-base text-gray-900">
              {{ errorMessage.sub }}
            </p>
          </div>
          <div
            class="flex mt-10 space-x-3 sm:border-l sm:border-transparent sm:pl-6"
          >
            <VButton :size="Size.sm" @click="$emit('click-home-button')">
              {{ $t('errorMessage.homeButton') }}
            </VButton>
            <VButton
              :size="Size.sm"
              :variant="Variant.secondary"
              @click="$emit('click-contact-button')"
            >
              {{ $t('errorMessage.contactButton') }}
            </VButton>
          </div>
        </div>
      </main>

      <div v-if="isTraceLogEnabled" class="mt-10 text-left">
        <p class="text-2xl">
          {{ error.statusCode + ' ' + error.statusMessage }}
        </p>

        <ul v-if="traceLogEntries.length >= 1">
          <li
            v-for="(line, index) in traceLogEntries"
            :key="index"
            class="py-1"
          >
            at {{ line }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { Size, Variant } from '@/types/enums'
import VButton from '@/components/VButton.vue'

const props = defineProps({
  error: {
    type: Object as PropType<{
      description: string
      message: string
      statusCode: string
      statusMessage: string
      url: string
    }>,
    default: null,
  },
  isTraceLogEnabled: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['click-home-button', 'click-contact-button'])

const { t } = useI18n()

const errorMessage = computed(() => {
  return props.error.statusCode === '404'
    ? { message: t('errorMessage.notFound'), sub: '' }
    : {
        message: t('errorMessage.unexpectedError.message'),
        sub: t('errorMessage.unexpectedError.sub'),
      }
})

const traceLogEntries = computed(() => {
  return props.error.description.split('at')
})
</script>
