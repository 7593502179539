import Toast, { PluginOptions, POSITION, TYPE } from 'vue-toastification'

export default defineNuxtPlugin((nuxtApp) => {
  const options: PluginOptions = {
    position: POSITION.BOTTOM_RIGHT,
    toastDefaults: {
      [TYPE.SUCCESS]: {
        hideProgressBar: true,
        icon: false,
      },
      [TYPE.ERROR]: {
        hideProgressBar: true,
        icon: false,
      },
      [TYPE.WARNING]: {
        hideProgressBar: true,
        icon: false,
      },
      [TYPE.INFO]: {
        hideProgressBar: true,
        icon: false,
      },
      [TYPE.DEFAULT]: {
        hideProgressBar: true,
        icon: false,
      },
    },
  }

  nuxtApp.vueApp.use(Toast, options)
})
