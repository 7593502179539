import { defineNuxtPlugin } from '#app'
import mixpanel from 'mixpanel-browser'
import VueHotjar from 'vue-hotjar-next'

export default defineNuxtPlugin((nuxtApp) => {
  const enableTracking = () => {
    if (isTrackingEnabled()) {
      const config = useRuntimeConfig().public

      mixpanel.init(config.MIXPANEL_TOKEN, {
        api_host: 'https://api-eu.mixpanel.com',
        ip: false,
      })

      if (config.HOTJAR_ID) {
        nuxtApp.vueApp.use(VueHotjar, {
          id: Number(config.HOTJAR_ID),
          isProduction: config.HOTJAR_ENABLED,
          snippetVersion: 6,
        })
      }
    }
  }

  const trackEvent = (event: string, params = {}) => {
    if (isTrackingEnabled()) {
      mixpanel.track(event, params)
    }
  }

  const setTrackingUser = (email?: string) => {
    if (isTrackingEnabled() && !!email) {
      mixpanel.identify()
      mixpanel.people.set({
        $email: email,
      })
    }
  }

  enableTracking()

  return {
    provide: {
      enableTracking: enableTracking,
      trackEvent: trackEvent,
      setTrackingUser: setTrackingUser,
    },
  }
})

function isTrackingEnabled() {
  const config = useRuntimeConfig()
  const consentCookie = useCookie('cc')

  return (
    !!config.public.MIXPANEL_TOKEN &&
    !!consentCookie.value &&
    !!consentCookie.value['analytics']
  )
}
