<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
      <ConsentModal />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
//NOTE - https://github.com/tailwindlabs/headlessui/issues/2913#issuecomment-1924681165
import { provideUseId } from '@headlessui/vue'

provideUseId(() => useId())
</script>
