import { createGtm } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const consentCookie = useCookie('cc')

  if (config.public.GTM_CONTAINER_ID && config.public.ENV === 'production') {
    nuxtApp.vueApp.use(
      createGtm({
        id: config.public.GTM_CONTAINER_ID,
        enabled: !!consentCookie.value && !!consentCookie.value['advertising'],
      })
    )
  }
})
