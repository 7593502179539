<template>
  <Switch
    v-model="value"
    :class="[
      value ? 'bg-cred disabled:bg-cred/35' : 'bg-gray-200',
      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 disabled:cursor-default',
    ]"
    @click="handleChange(!value)"
  >
    <span
      aria-hidden="true"
      :class="[
        value ? 'translate-x-5' : 'translate-x-0',
        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
      ]"
    />
    <span class="hidden">{{ name }}</span>
  </Switch>
</template>

<script setup lang="ts">
import { Switch } from '@headlessui/vue'
import { useField } from 'vee-validate'

const props = defineProps({
  value: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    required: true,
  },
})

const { value, handleChange } = useField(props.name, undefined, {
  initialValue: props.value,
})
</script>
