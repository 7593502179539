import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_2ruDq4uEtU from "/vercel/path0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import preview_client_DXs5LYnWiu from "/vercel/path0/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.mjs";
import floating_vue_EIcJ7xiw0h from "/vercel/path0/.nuxt/floating-vue.mjs";
import switch_locale_path_ssr_8BKffvaEi5 from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_iGLjBbor9q from "/vercel/path0/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import calendar_plugin_client_bXHziBbXk3 from "/vercel/path0/plugins/calendar-plugin.client.ts";
import error_handler_client_sQhJOBiqXf from "/vercel/path0/plugins/error-handler.client.ts";
import google_tag_manager_client_EITxCwySk0 from "/vercel/path0/plugins/google-tag-manager.client.ts";
import notifications_client_mBACvv4vx7 from "/vercel/path0/plugins/notifications.client.ts";
import scroll_to_top_gyAlBetrpL from "/vercel/path0/plugins/scroll-to-top.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/plugins/sentry.client.ts";
import tracking_client_0HR1cQSG9M from "/vercel/path0/plugins/tracking.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  supabase_client_2ruDq4uEtU,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  preview_client_DXs5LYnWiu,
  floating_vue_EIcJ7xiw0h,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  plugin_iGLjBbor9q,
  calendar_plugin_client_bXHziBbXk3,
  error_handler_client_sQhJOBiqXf,
  google_tag_manager_client_EITxCwySk0,
  notifications_client_mBACvv4vx7,
  scroll_to_top_gyAlBetrpL,
  sentry_client_shVUlIjFLk,
  tracking_client_0HR1cQSG9M
]