import { defineNuxtPlugin } from '#app'
import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(() => {
  const {
    public: { SENTRY_DSN, ENV, SOURCE_VERSION },
  } = useRuntimeConfig()

  if (ENV === 'development') return

  const app = createApp({})

  Sentry.init({
    app,
    dsn: SENTRY_DSN,
    environment: ENV,
    integrations: [
      Sentry.browserTracingIntegration({
        router: useRouter(),
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.2,
    replaysOnErrorSampleRate: 1,
    release: SOURCE_VERSION,
  })
})
