const REDIRECTS = [
  {
    pattern: '/request/assets/',
    target: '/request-quote/',
  },
  {
    pattern: '/request/timing/',
    target: '/request-quote/',
  },
  {
    pattern: '/auflieger/',
    target: '/request-quote/',
  },
  {
    pattern: '/sattelzugmaschinen/',
    target: '/request-quote/',
  },
  {
    pattern: '/plattform/requests/:id',
    target: '/plattform/marketplace/:id',
  },
]

export default defineNuxtRouteMiddleware((to) => {
  for (const redirect of REDIRECTS) {
    const newPath = redirect.pattern.includes(':')
      ? dynamicPathRedirect(to.path, redirect)
      : staticPathRedirect(to.path, redirect)

    if (newPath) {
      return navigateTo(
        {
          path: newPath,
          query: to.query,
        },
        { redirectCode: 301 }
      )
    }
  }
})

// MARK: Dynamic path redirect

function dynamicPathRedirect(
  toPath: string,
  redirect: { pattern: string; target: string }
) {
  const regex = new RegExp(
    '^' + redirect.pattern.replace(/:([a-zA-Z0-9_]+)/g, '([^/]+)') + '/?$'
  )

  const match = toPath.match(regex)

  if (match) {
    const params = paramsFromPattern(redirect.pattern, match)
    return targetPathWithDynamicValues(redirect.target, params)
  }

  return null
}

function paramsFromPattern(pattern: string, match: RegExpMatchArray) {
  const params: { [key: string]: string } = {}
  const keys = [...pattern.matchAll(/:([a-zA-Z0-9_]+)/g)]

  keys.forEach((key, index) => {
    params[key[1]] = match[index + 1]
  })

  return params
}

function targetPathWithDynamicValues(
  target: string,
  params: { [key: string]: string }
) {
  let targetUrl = target

  for (const [key, value] of Object.entries(params)) {
    targetUrl = targetUrl.replace(`:${key}`, value)
  }

  return targetUrl
}

// MARK: Static path redirect

function staticPathRedirect(
  toPath: string,
  redirect: { pattern: string; target: string }
) {
  const path = toPath.slice(-1) === '/' ? toPath : `${toPath}/`
  return redirect.pattern === path ? redirect.target : null
}
